import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TransactionResponse } from '@ethersproject/providers'
import { useActiveWeb3React } from '../../hooks'
import { useBondNFTContract, useBondVaultContract, useTendieTokenContract } from '../../hooks/useContract'
import { bondVaultContract as bondVaultContractAddr } from '../../TENET_address.json'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { ButtonPrimary } from '../Button'

const Wrapper = styled.div`
  display: flex;
  border-radius: 3rem;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ffffff;
  max-height: 600px; /* Adjust the height value as needed */
  overflow-y: auto;
`

const Title = styled.div`
  text-align: center !important;
  font-weight: 500;
  font-size: 20px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
`

const BondCardDivStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  font-weight: 500;
  justify-content: space-between;
  align-items: space-between;
  background: #feee02;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
`

const BondCardStyle = styled.text`
  font-size: 14px;
`

const Label = styled.text`
  color: black;
  font-weight: 500;
  font-size: 14px;
`

const StatA = styled.text`
  color: black;
  font-weight: 500;
  font-size: 20px;
`

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 12px;
  padding: 0.75rem 1rem 0rem 1rem;
  width: 100%;
  text-align: left;
`

const Stat = styled.text`
  margin-top: 12px;
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
`

const StatInsideWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const StatSign = styled.span`
  color: black;
  font-size: 14px;
  text-align: right;
  font-weight: 500;
  margin-left: 4px;
`

const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface BondNft {
  tokenId: number
  payout: BigInt
  payoutClaimed: BigInt
  percentBonus: number
}

const BondCard = ({
  // payoutAmount,
  bondNft,
  buttonFunction,
  depSwap
}: {
  bondNft: BondNft
  buttonFunction: (tokenIdList: number[]) => Promise<void>
  depSwap: boolean
}) => {
  const handleClaim = async () => {
    await buttonFunction([bondNft.tokenId]) // Pass the index of the BondNft object to claimBond function
  }
  // Render the BondCard component with the bondNft data
  return (
    <BondCardDivStyle>
      <BondCardStyle>
        <Label>Bond Bonus Yield</Label> <br />
        <StatA>{bondNft.percentBonus.toFixed(2)}</StatA>
        <StatSign>%</StatSign>
      </BondCardStyle>
      <ButtonPrimary style={{ padding: 0 }} onClick={handleClaim}>
        {depSwap ? 'Deposit' : 'Withdraw'}
      </ButtonPrimary>
    </BondCardDivStyle>
  )
}

export default function BondVaultList() {
  const { account, library } = useActiveWeb3React()
  const bondNFTContract = useBondNFTContract()
  const bondVaultContract = useBondVaultContract()
  const tendieContract = useTendieTokenContract()
  const addTransaction = useTransactionAdder()

  const [bondNfts, setBondNfts] = useState<BondNft[]>([])
  const [vaultNfts, setVaultNfts] = useState<BondNft[]>([])
  const [payoutPercent, setPayoutPercent] = useState(0)

  const fetchBondNfts = async () => {
    if (!account || !library || !bondNFTContract || !bondVaultContract || !tendieContract) return

    const depNfts = await bondVaultContract.getDepositedTokens(account)
    const payoutPercentNum = await bondVaultContract.bondPayoutAmountDivSupply(account)
    const totalSupply = await tendieContract.totalSupply()

    setPayoutPercent(payoutPercentNum / 10)

    const percentPerTendie = ((10**18)/(totalSupply))*10000
    console.log(percentPerTendie)

    const balance = await bondNFTContract.balanceOf(account)
    const tokenIds: number[] = []

    for (let i = 0; i < balance; i++) {
      const tokenId = await bondNFTContract.tokenOfOwnerByIndex(account, i)
      tokenIds.push(tokenId.toNumber())
    }

    const bondNfts: BondNft[] = []

    for (const tokenId of tokenIds) {
      const bondData = await bondNFTContract.getBondData(tokenId)

      const bondNft: BondNft = {
        tokenId: tokenId,
        payout: bondData[0],
        payoutClaimed: bondData[1],
        percentBonus: (percentPerTendie * bondData[1]) / 10 ** 18
      }

      if (bondNft.payout.toString() === bondNft.payoutClaimed.toString()) {
        bondNfts.push(bondNft)
      }
    }

    for (const tokenId of depNfts) {
      const bondData = await bondNFTContract.getBondData(tokenId)

      const bondNft: BondNft = {
        tokenId: tokenId,
        payout: bondData[0],
        payoutClaimed: bondData[1],
        percentBonus: (percentPerTendie * bondData[1]) / 10 ** 18
      }

      vaultNfts.push(bondNft)
    }

    setVaultNfts(vaultNfts)
    setBondNfts(bondNfts)
  }

  useEffect(() => {
    fetchBondNfts()
  }, [account, library])

  const depositNFTs = (indexList: number[]) => {
    if (!account || !library || !bondNFTContract || !bondVaultContract) return
    try {
      bondVaultContract
        .depositNfts(indexList)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: 'Deposit ' + indexList + ' NFTS Succeed.'
          })
        })
        .catch((error: Error) => {
          console.debug('Failed to deposit NFTS', error)
        })
    } catch (error) {
      console.error('Error depositing:', error)
    }
  }

  const depositBond = async (indexList: number[]) => {
    if (!account || !library || !bondNFTContract || !bondVaultContract) return

    const approved = await bondNFTContract.isApprovedForAll(account, bondVaultContractAddr)

    if (approved) {
      depositNFTs(indexList)
    } else {
      // Approve NFTs
      try {
        bondNFTContract
          .setApprovalForAll(bondVaultContractAddr, true)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Approve NFTS Succeed.'
            })
            // Deposit NFTs
            depositNFTs(indexList)
          })
          .catch((error: Error) => {
            console.debug('Failed to approve NFTS', error)
          })
      } catch (error) {
        console.error('Error approving:', error)
      }
    }
  }

  const withdrawBond = async (indexList: number[]) => {
    if (!account || !library || !bondVaultContract) return

    try {
      bondVaultContract
        .withdrawNfts(indexList)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: 'Withdraw ' + indexList + ' NFTS Succeed.'
          })
        })
        .catch((error: Error) => {
          console.debug('Failed to withdraw NFTS', error)
        })
    } catch (error) {
      console.error('Error withdrawing:', error)
    }
  }

  return (
    <Wrapper>
      <StatsContainer>
        <StatWrapper>
          <Label>Staking Bonus Yield</Label>
          <StatInsideWrapper>
            <Stat>{payoutPercent.toFixed(2)}</Stat>
            <StatSign>%</StatSign>
          </StatInsideWrapper>
        </StatWrapper>
      </StatsContainer>
      <Title>Vault:</Title>
      {vaultNfts.map((bondNft, index) => (
        <BondCard
          key={index}
          bondNft={bondNft}
          buttonFunction={withdrawBond}
          depSwap={false}
        />
      ))}
      <Title>Wallet:</Title>
      {bondNfts.map((bondNft, index) => (
        <BondCard
          key={index}
          bondNft={bondNft}
          buttonFunction={depositBond}
          depSwap={true}
        />
      ))}
    </Wrapper>
  )
}
