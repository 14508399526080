import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
//import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
//import { SectionBreak } from './styleds'

function BondSummary({ bond }: { bond: Bond }) {
    const theme = useContext(ThemeContext);
  
    return (
      <>
        <AutoColumn style={{ padding: '0 20px' }}>
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                LP Bond Value in USD:
              </TYPE.black>
            </RowFixed>
            <RowFixed>
              <TYPE.black color={theme.text1} fontSize={14}>
                {bond.AmountInDollars?.toFixed(4) ?? '-'}
              </TYPE.black>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                Vesting Days:
              </TYPE.black>
            </RowFixed>
            <RowFixed>
              <TYPE.black color={theme.text1} fontSize={14}>
                5
              </TYPE.black>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                Max Bond Amount:
              </TYPE.black>
            </RowFixed>
            <RowFixed>
              <TYPE.black color={theme.text1} fontSize={14}>
                1% Tendie Supply
              </TYPE.black>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </>
    );
  }

export interface AdvancedBondingDetailsProps {
  bond?: Bond
}

interface Bond {
    AmountInDollars: number;
    VestingTerm: number;
    MaxBondAmount: number;
  }

export function AdvancedBondingDetails({ bond }: AdvancedBondingDetailsProps) {

  return (
    <AutoColumn gap="md">
      {bond && (
        <>
          <BondSummary bond={bond} />
          {/* Rest of the component */}
        </>
      )}
    </AutoColumn>
  )
}
