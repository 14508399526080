import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import AppBody from '../AppBody'
import StakingStats from '../../components/StakingStats'
import StakingDeposit from '../../components/StakingDeposit'
import StakeVaultTabs from '../../components/StakeVaultTabs'
import TypedOutText from '../../components/TypedOutText'
//import { useMultipleContractSingleData } from '../../state/multicall/hooks';
import { useActiveWeb3React } from '../../hooks'
// Inside the Bonding component
import { transparentize } from 'polished'
import { AlertTriangle } from 'react-feather'
import { useLPTokenContract, useStakingContract, useTendieTokenContract } from '../../hooks/useContract'

interface StakingStats {
  TendiePrice: number
  TendieStaked: number
}

/*
interface BondNft {
    TokenId: number;
    CreationStamp: number;
    VestingTerm: number;
    TotalPayout: number;
    Payout: number;
}

interface BondList {
    BondNftList: BondNft[];
}
 
*/

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AppBodyContainer = styled.div`
  flex: 1;
`

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.red1};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`

export function ErrorModal({ error }: { error: string }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </SwapCallbackErrorInnerAlertTriangle>
      <p>{error}</p>
    </SwapCallbackErrorInner>
  )
}

export default function Staking() {
  //const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

  const { account, library } = useActiveWeb3React()

  const stakingContract = useStakingContract()
  const LPContract = useLPTokenContract()
  const TendiTokenContract = useTendieTokenContract()

  const [stakingStats, setStakingStats] = useState<StakingStats | null>(null)

  useEffect(() => {
    const fetchStakingData = async () => {
      if (!account || !library || !stakingContract || !LPContract || !TendiTokenContract) return

      const reserves = await LPContract.getReserves()
      // console.log(reserves[0] / 10 ** 18)

      const TendUsd = (reserves[1] / reserves[0]) * 0.14
      // console.log(TendUsd)

      const tendieDepped = await stakingContract.poolInfo(0)

      const initialstakingStats: StakingStats = {
        TendiePrice: TendUsd,
        TendieStaked: tendieDepped[4] / 10 ** 18
      }

      setStakingStats(initialstakingStats)
    }

    fetchStakingData()

  }, [stakingContract, LPContract, TendiTokenContract, account, library])

  const active = false // Hardcoded 'active' variable

  if (active) {
    const textToType = 'Hello, this section will be live soon!'
    return (
      <AppBody>
        <TypedOutText text={textToType} />
      </AppBody>
    )
  } else {
    return (
      <PageContainer>
        <AppBodyContainer>
          <AppBody>
            {/* Tab */}
            <StakeVaultTabs active="stake" />
            {/* Status */}
            <StakingStats stakingStats={stakingStats} />
            {/* Functionality */}
            <StakingDeposit stakingStats={stakingStats} setStakingStats={setStakingStats} />
          </AppBody>
        </AppBodyContainer>
      </PageContainer>
    )
  }
}
