import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface TypedOutTextProps {
  text: string;
}

const StyledText = styled.div`
  font-weight: 500;
  font-size: 2rem;
  padding: 1rem;
`;

const TypedOutText: React.FC<TypedOutTextProps> = ({ text }) => {
  const [typedText, setTypedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typeNextCharacter = () => {
      if (currentIndex === text.length) return; // Stop typing when all characters are typed
      
      const nextCharacter = text[currentIndex];
      setTypedText((prevTypedText) => prevTypedText + nextCharacter);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    const typingInterval = setInterval(typeNextCharacter, 50); // Type a character every 100ms

    return () => {
      clearInterval(typingInterval); // Clean up the interval on component unmount
    };
  }, [currentIndex, text]);

  return <StyledText>{typedText}</StyledText>;
};

export default TypedOutText;
