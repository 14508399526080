import React, { useState, useRef, useEffect, useMemo } from 'react'
import { ethers } from 'ethers'
import { TransactionResponse } from '@ethersproject/providers'
import { Token, ChainId, TokenAmount } from 'moonbeamswap'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useTokenBalances } from '../../state/wallet/hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { stakingContract as stakingContractAddr } from '../../TENET_address.json'
import { ButtonConfirmed } from '../../components/Button'
import { useStakingContract } from '../../hooks/useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LPTokenContract, TendiesTokenContract } from '../../TENET_address.json'
import {  useBondVaultContract } from '../../hooks/useContract'
import { bondVaultContract as bondVaultContractAddr } from '../../TENET_address.json'
//import Loader from '../../components/Loader'

const Wrapper = styled.div`
  display: flex;
  border-radius: 3rem;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ffffff;
`

const DepositContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 12px;
  padding: 0.75rem 1rem 0rem 1rem;
  width: 100%;
  text-align: left;
`

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
`

const Label = styled.text`
  color: black;
  font-weight: 500;
  font-size: 14px;
`

const Stat = styled.text`
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`

const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`

const StyledInput = styled.input`
  text-align: left;
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
  outline: none;
  padding: 3px !important;
  width: 100%;
  height: 28px;
  margin-bottom: 5px;
`

const DollarValue = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
`

const StyledInput2Container = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  max-width: 50%;
  gap: 8px;
`

const StyledBalanceMax = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  `};
`

interface DynamicDollarValueProps {
  position: number
}

const DynamicDollarValue = styled(DollarValue)<DynamicDollarValueProps>`
  text-align: left;
`

interface StakingStats {
  TendiePrice: number
  TendieStaked: number
}

interface StakingStatsProps {
  stakingStats: StakingStats | null
  setStakingStats: React.Dispatch<React.SetStateAction<StakingStats | null>>
}

function removeLeadingZeros(str: string) {
  return str.replace(/^0+/, match => (match.length > 1 ? match : ''))
}

function removeTrailingZeros(str: string) {
  return str.replace(/0+$/, '')
}

function truncateString(str: string, maxLength: number) {
  return str.slice(0, maxLength)
}

function limitSignificantFigures(number: number, maxSigFigs: number) {
  const numberString = number.toString()
  const dotIndex = numberString.indexOf('.')

  if (dotIndex !== -1) {
    let integerPart = numberString.substr(0, dotIndex)
    let decimalPart = numberString.substr(dotIndex + 1)

    integerPart = removeLeadingZeros(integerPart)
    decimalPart = removeTrailingZeros(decimalPart)

    if (integerPart.length + decimalPart.length <= maxSigFigs) {
      // No truncation needed
      return numberString
    }

    if (integerPart === '' || integerPart === '0') {
      const truncatedDecimalPart = truncateString(decimalPart, maxSigFigs - 1)
      const truncatedNumberString = '0.' + truncatedDecimalPart
      return truncatedNumberString
    }

    const truncatedDecimalPart = truncateString(decimalPart, maxSigFigs - integerPart.length)
    const truncatedNumberString = integerPart + (truncatedDecimalPart ? '.' + truncatedDecimalPart : '')
    return truncatedNumberString
  }

  return removeLeadingZeros(numberString)
}

/*
  function convertToTokenAmount(value: number, token: Token) {
    // Convert the numerical value to a string and parse it into a BigInt
    const amount = BigInt(value.toString());
  
    // Create a new TokenAmount object with the token and amount
    const tokenAmount = new TokenAmount(token, amount);
  
    return tokenAmount;
  }
  */

// ...

// ...

export default function StakingDeposit({ stakingStats /* , setStakingStats */ }: StakingStatsProps) {
  const lpToken = useMemo(() => new Token(ChainId.TENET, LPTokenContract, 18, 'TENLP-V2', 'Tendieswap LP-V2'), [])
  const tendieToken = useMemo(
    () => new Token(ChainId.TENET, TendiesTokenContract, 18, 'TENDIE', 'Tendie Token'),
    []
  )

  const bondVaultContract = useBondVaultContract()

  const stakingContract = useStakingContract(stakingContractAddr)
  const addTransaction = useTransactionAdder()
  const { account, chainId, library } = useActiveWeb3React()

  const [depositValue, setDepositValue] = useState('0.0')
  const [withdrawalValue, setWithdrawalValue] = useState('0.0')
  const [dollarValuePosition, setDollarValuePosition] = useState<number>(0)
  const [approveAmount, setApproveAmount] = useState<TokenAmount>(new TokenAmount(tendieToken, BigInt(1)))
  const [payoutPercent, setPayoutPercent] = useState(0)

  const [rewards, setRewards] = useState(0)
  const [userDeposited, setUserDeposited] = useState(0)

  const balances = useTokenBalances(account ?? undefined, [lpToken, tendieToken])

  const TendieBalance = parseFloat(balances?.[tendieToken.address]?.toSignificant(10) || '0')

  const inputRef = useRef<HTMLInputElement>(null)

  function handleDeposit() {
    if (stakingContract && account && library && chainId) {
      try {
        const val = ethers.utils.parseEther(depositValue)
        stakingContract
          .deposit(0, val)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Deposit ' + depositValue + 'Tendies Succeed.'
            })
          })
          .catch((error: Error) => {
            console.debug('Failed to deposit token', error)
          })
      } catch (error) {
        console.error('Error depositing:', error)
      }
    }
  }

  async function handleWithdraw() {
    if (stakingContract && library) {
      try {
        const val = ethers.utils.parseEther(withdrawalValue)

        stakingContract
          .withdraw(0, val)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Withdraw ' + withdrawalValue + 'Tendies Succeed.'
            })
          })
          .catch((error: Error) => {
            console.debug('Failed to withdraw token', error)
          })
      } catch (error) {
        console.error('Error withdrawing:', error)
      }
    }
  }

  async function handleHarvest() {
    if (stakingContract && library) {
      try {
        stakingContract
          .deposit(0, 0)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Harvest Succeed.'
            })
          })
          .catch((error: Error) => {
            console.debug('Failed to deposit token', error)
          })

        // const tx = await stakingContract.deposit(0, 0)
        // await tx.wait()
        // console.log('Harvest successful')
      } catch (error) {
        console.error('Error harvesting:', error)
      }
    }
  }

  useEffect(() => {
    const fetchStakingData = async () => {
      if (!account || !library || !stakingContract || !bondVaultContract) return

      const pending = await stakingContract.pendingTendies(0, account)
      setRewards(pending / 10 ** 18)

      const userInfo = await stakingContract.userInfo(0, account)
      setUserDeposited(userInfo[0] / 10 ** 18)

      const payoutPercentNum = await bondVaultContract.bondPayoutAmountDivSupply(account)
      setPayoutPercent(payoutPercentNum / 10)
    }

    fetchStakingData()


    const inputElement = inputRef.current
    if (inputElement) {
      const inputValueLength = depositValue.length
      const position = +(14 * inputValueLength) // Adjust the multiplier based on the desired spacing
      setDollarValuePosition(position)
    }
  }, [depositValue, account, library, stakingContract])

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallback(approveAmount, stakingContractAddr)
  // console.log(approval)
  // check if user has gone through approval process, used to show two step buttons, reset on token change

  // mark when a user has submitted an approval, reset onTokenSelection for input field

  const handleDepInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const depositValue = event.target.value

    // Check if depositValue contains only numbers (including decimals)
    if (/^\d*\.?\d*$/.test(depositValue)) {
      if (parseFloat(depositValue) > TendieBalance) {
        if (depositValue != null) {
          setDepositValue(limitSignificantFigures(TendieBalance, 4).toString())
        }
        if (TendieBalance < 1) {
          // console.log(TendieBalance)
        }
        // console.log('Hello ' + Number(TendieBalance))
        setApproveAmount(new TokenAmount(tendieToken, BigInt(TendieBalance * 10 ** 18)))
      } else {
        if (depositValue != null) {
          setDepositValue(depositValue)
        }
        if (parseFloat(depositValue) > 0) {
          setApproveAmount(new TokenAmount(tendieToken, BigInt(parseFloat(depositValue) * 10 ** 18)))
        }
      }
    }
  }

  const handleWitInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const withdrawalValue = event.target.value

    // Check if depositValue contains only numbers (including decimals)
    if (/^\d*\.?\d*$/.test(withdrawalValue)) {
      if (parseFloat(withdrawalValue) > userDeposited) {
        if (withdrawalValue != null) {
          setWithdrawalValue(limitSignificantFigures(userDeposited, 4).toString())
        }
      } else {
        if (withdrawalValue != null) {
          setWithdrawalValue(withdrawalValue)
        }
      }
    }
  }

  const onMaxDeposit = () => {
    setDepositValue(limitSignificantFigures(TendieBalance, 4).toString())
    setApproveAmount(new TokenAmount(tendieToken, BigInt(TendieBalance * 10 ** 18)))
  }

  const onMaxWithdraw = () => {
    setWithdrawalValue(limitSignificantFigures(userDeposited, 4).toString())
    // setApproveAmount(new TokenAmount(tendieToken, BigInt(TendieBalance * 10 ** 18)))
  }

  const TendieUsd =
    depositValue === '0' || depositValue === '' || parseFloat(depositValue) === 0
      ? 0
      : stakingStats?.TendiePrice
      ? parseFloat(depositValue) * stakingStats.TendiePrice
      : 0
  const TendieUsdStaked =
    withdrawalValue === '0' || withdrawalValue === '' || parseFloat(withdrawalValue) === 0
      ? 0
      : stakingStats?.TendiePrice
      ? parseFloat(withdrawalValue) * stakingStats.TendiePrice
      : 0

  return (
    <Wrapper>
      <DepositContainer>
        <InputContainer>
          <Label>Deposit</Label>
          <Label>Balance: {TendieBalance.toFixed(2)}</Label>
        </InputContainer>

        <InputContainer>
          <StyledInputContainer>
            <div>
              <StyledInput
                maxLength={7}
                ref={inputRef}
                type="text"
                value={depositValue}
                onChange={handleDepInputChange}
              />
              <DynamicDollarValue position={dollarValuePosition}>${TendieUsd.toFixed(2)}</DynamicDollarValue>
            </div>
            <StyledBalanceMax onClick={onMaxDeposit}>MAX</StyledBalanceMax>
          </StyledInputContainer>

          <Stat>TENDIES</Stat>
        </InputContainer>
      </DepositContainer>

      <ButtonContainer>
        <ButtonConfirmed disabled={approval !== ApprovalState.NOT_APPROVED} onClick={approveCallback}>
          Approve
        </ButtonConfirmed>
      </ButtonContainer>
      <ButtonContainer>
        {account && (
          <ButtonConfirmed
            disabled={approval !== ApprovalState.APPROVED || Number(depositValue) === 0}
            onClick={handleDeposit}
          >
            Deposit
          </ButtonConfirmed>
        )}
      </ButtonContainer>

      <DepositContainer>
        <InputContainer>
          <Label>Withdraw</Label>
          <Label>Balance: {userDeposited.toFixed(2)}</Label>
        </InputContainer>
        <InputContainer>
          <StyledInputContainer>
            <div>
              <StyledInput
                maxLength={7}
                ref={inputRef}
                type="text"
                value={withdrawalValue}
                onChange={handleWitInputChange}
              />
              <DynamicDollarValue position={dollarValuePosition}>${TendieUsdStaked.toFixed(2)}</DynamicDollarValue>
            </div>
            <StyledBalanceMax onClick={onMaxWithdraw}>MAX</StyledBalanceMax>
          </StyledInputContainer>
          <Stat>TENDIES</Stat>
        </InputContainer>
      </DepositContainer>
      <ButtonContainer>
        {account && (
          <ButtonConfirmed disabled={!ApprovalState.APPROVED || Number(withdrawalValue) === 0} onClick={handleWithdraw}>
            Withdraw
          </ButtonConfirmed>
        )}
      </ButtonContainer>

      <DepositContainer>
        <InputContainer>
          <Label>Pending Rewards + {payoutPercent} % Bond Yield</Label>
        </InputContainer>
        <InputContainer>
          <StyledInput2Container>
            <Stat>{(rewards+ (rewards * (payoutPercent/100))).toFixed(0)}</Stat>
            <DollarValue>
              ${stakingStats?.TendiePrice ? ((rewards + (rewards * (payoutPercent/100))) * stakingStats.TendiePrice).toFixed(2) : (0).toFixed(2)}
            </DollarValue>
          </StyledInput2Container>
          <Stat>TENDIES</Stat>
        </InputContainer>
      </DepositContainer>

      <ButtonContainer>
        {account && (
          <ButtonConfirmed disabled={!ApprovalState.APPROVED} onClick={handleHarvest}>
            Harvest (Bonus Included)
          </ButtonConfirmed>
        )}
      </ButtonContainer>
    </Wrapper>
  )
}
