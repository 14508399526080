import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  border-radius: 3rem;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ffffff;
`

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 12px;
  padding: 0.75rem 1rem 0rem 1rem;
  width: 100%;
  text-align: left;
`

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  padding: 0.75rem 1rem 0rem 1rem;
  border-radius: 20px;
  width: 100%;
`

const ProgressBar = styled.div`
  width: 100%;
  height: 24px;
  background-color: rgba(255, 232, 76, 0.5);
  border-radius: 20px;
`

const Progress = styled.div`
  height: 100%;
  background-color: #feee02;
  border-radius: 20px;
`

const Label = styled.text`
  color: black;
  font-weight: 500;
  font-size: 14px;
`

const Stat = styled.text`
  margin-top: 12px;
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
`

const StatInsideWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const StatSign = styled.span`
  color: black;
  font-size: 14px;
  text-align: right;
  font-weight: 500;
  margin-left: 4px;
`

const ProgressStat = styled.text`
  color: black;
  font-weight: 500;
  font-size: 14px;
`

const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface BondStats {
  BondPrice: number
  TendiePrice: number
  LPPrice: number
  TotalEmissions: number
  MaxLpBond: number
  TendieSupply: number
}

interface BondingStatsProps {
  bondStats: BondStats | null
}

export default function BondingStats({ bondStats }: BondingStatsProps) {
  if (!bondStats) {
    return (
      <StatsContainer>
        <Stat>Stats Loading...</Stat>
      </StatsContainer>
    )
  }

  const { BondPrice, TendiePrice, LPPrice, TotalEmissions } = bondStats

  /*
  MATH

  Want to show LP US price

  Want to show Bond ROI

  */

  const payout = 1 / BondPrice
  // console.log(TotalEmissions)

  const LPtoTendie = LPPrice / TendiePrice

  const roi = (payout / LPtoTendie - 1) * 100

  // Calculate the progress based on the formula: progress = tokensBonded / 35000000
  const emissionPercentage = TotalEmissions / 350000

  //const DivConst = 1000000;

  return (
    <Wrapper>
      <StatsContainer>
        <StatWrapper>
          <Label>LP Token Price</Label>
          <StatInsideWrapper>
            <Stat>{LPPrice.toFixed(2)}</Stat>
            <StatSign>$</StatSign>
          </StatInsideWrapper>
        </StatWrapper>
        <StatWrapper>
          <Label>5 Day Return</Label>
          <StatInsideWrapper>
            <Stat>{roi.toFixed(2)}</Stat>
            <StatSign>%</StatSign>
          </StatInsideWrapper>
        </StatWrapper>
      </StatsContainer>
      <ProgressBarContainer>
        <Label>Token Bonding Emissions</Label>
        <Stat>
          {emissionPercentage.toFixed(1)}% <ProgressStat>of 35 Million TENDIES</ProgressStat>
        </Stat>
        <ProgressBar>
          <Progress style={{ width: `${emissionPercentage}%` }} />
        </ProgressBar>
      </ProgressBarContainer>
    </Wrapper>
  )
}
