import React from 'react'
import AppBody from '../AppBody'
import BondClaimList from '../../components/BondClaimList' // Import the BondingStats component
import BondClaimTabs from '../../components/BondClaimTabs'

export default function Claiming() {
  return (
    <AppBody>
      {/* Tab */}
      <BondClaimTabs active={'claim'} />
      <BondClaimList /> {/* Replace TypedOutText with BondingStats */}
    </AppBody>
  )
}
