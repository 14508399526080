import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { TransactionResponse } from '@ethersproject/providers'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { ButtonPrimary } from '../Button'
import { useBondNFTContract } from '../../hooks/useContract'
import { bondNFTContract as bondNFTContractAddr } from '../../TENET_address.json'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { darken } from 'polished'

const Wrapper = styled.div`
  display: flex;
  border-radius: 3rem;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ffffff;
  max-height: 600px; /* Adjust the height value as needed */
  overflow-y: auto;
`

const Title = styled.div`
  text-align: center !important;
  font-weight: 500;
  font-size: 20px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-self: center;
  height: 2rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.primary1};
    text-decoration: underline;
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
`

const BondCardDivStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  font-weight: 500;
  justify-content: space-between;
  align-items: space-between;
  background: #feee02;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
`

const BondCardStyle = styled.text`
  font-size: 14px;
`

const Label = styled.text`
  color: black;
  font-weight: 500;
  font-size: 14px;
`

const Stat = styled.text`
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
`

interface BondNft {
  tokenId: number
  payout: BigInt
  payoutClaimed: BigInt
  vestingTerm: BigInt
  vestingStartTimestamp: BigInt
  lastClaimTimestamp: BigInt
  truePricePaid: BigInt
}

const getCurrentUnixTimestamp = (): number => {
  return Math.floor(new Date().getTime() / 1000)
}

const BondCard = ({ bondNft, claimBond }: { bondNft: BondNft; claimBond: (tokenId: number) => Promise<void> }) => {
  const handleClaim = async () => {
    await claimBond(bondNft.tokenId) // Pass the index of the BondNft object to claimBond function
  }
  // Render the BondCard component with the bondNft data
  return (
    <BondCardDivStyle>
      <BondCardStyle>
        <Label>Tendie Claimed:</Label> <br />
        <Stat>
          {(parseFloat(bondNft.payoutClaimed.toString()) / 10 ** 18).toFixed(2)} /{' '}
          {(parseFloat(bondNft.payout.toString()) / 10 ** 18).toFixed(2)}
        </Stat>
      </BondCardStyle>
      <BondCardStyle>
        <Label>Vesting:</Label> <br />
        <Stat>
          {(getCurrentUnixTimestamp() - Number(bondNft.vestingStartTimestamp)) / Number(bondNft.vestingTerm) < 1
            ? (
                ((getCurrentUnixTimestamp() - Number(bondNft.vestingStartTimestamp)) / Number(bondNft.vestingTerm)) *
                100
              ).toFixed(2)
            : 100}{' '}
          % of 5 Days
        </Stat>
      </BondCardStyle>
      <BondCardStyle>
        <Label>Harvest:</Label> <br />
        <Stat>
          {(parseFloat(bondNft.payout.toString()) / 10 ** 18) *
            ((getCurrentUnixTimestamp() - Number(bondNft.vestingStartTimestamp)) / Number(bondNft.vestingTerm)) -
            parseFloat(bondNft.payoutClaimed.toString()) / 10 ** 18 >
          parseFloat(bondNft.payout.toString()) / 10 ** 18 - parseFloat(bondNft.payoutClaimed.toString()) / 10 ** 18
            ? parseFloat(bondNft.payout.toString()) / 10 ** 18 - parseFloat(bondNft.payoutClaimed.toString()) / 10 ** 18
            : (
                (parseFloat(bondNft.payout.toString()) / 10 ** 18) *
                  ((getCurrentUnixTimestamp() - Number(bondNft.vestingStartTimestamp)) / Number(bondNft.vestingTerm)) -
                parseFloat(bondNft.payoutClaimed.toString()) / 10 ** 18
              ).toFixed(2)}
        </Stat>
      </BondCardStyle>
      <ButtonPrimary style={{ padding: 0 }} onClick={handleClaim}>
        Claim
      </ButtonPrimary>
    </BondCardDivStyle>
  )
}

export default function BondClaimList() {
  const { account, library } = useActiveWeb3React()
  const bondNFTContract = useBondNFTContract(bondNFTContractAddr)
  const addTransaction = useTransactionAdder()
  const [bondNfts, setBondNfts] = useState<BondNft[]>([])

  useEffect(() => {
    const fetchBondNfts = async () => {
      if (!account || !library || !bondNFTContract) return

      const balance = await bondNFTContract.balanceOf(account)
      const tokenIds: number[] = []

      for (let i = 0; i < balance; i++) {
        const tokenId = await bondNFTContract.tokenOfOwnerByIndex(account, i)
        tokenIds.push(tokenId.toNumber())
      }

      const bondNfts: BondNft[] = []

      for (const tokenId of tokenIds) {
        const bondData = await bondNFTContract.getBondData(tokenId)

        const bondNft: BondNft = {
          tokenId: tokenId,
          payout: bondData[0],
          payoutClaimed: bondData[1],
          vestingTerm: bondData[2],
          vestingStartTimestamp: bondData[3],
          lastClaimTimestamp: bondData[4],
          truePricePaid: bondData[5]
        }

        if (bondNft.payout.toString() !== bondNft.payoutClaimed.toString()) {
          bondNfts.push(bondNft)
        }
      }

      setBondNfts(bondNfts)
    }

    fetchBondNfts()
  }, [account, library, bondNFTContract])

  const claimBond = async (index: number) => {
    if (!account || !library || !bondNFTContract) return

    try {
      bondNFTContract
        .claimBond(index)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: 'Claim ' + index + ' NFT Succeed.'
          })
        })
        .catch((error: Error) => {
          console.debug('Failed to Claim NFT', error)
        })
    } catch (error) {
      console.error('Error Claiming:', error)
    }
  }

  return (
    <Wrapper>
      <Title>Your Bonds</Title>
      {bondNfts.map((bondNft, index) => (
        <BondCard key={index} bondNft={bondNft} claimBond={claimBond} />
      ))}

      <StyledNavLink id={`bond-nav-link`} to={'/vault'}>
        Go to Vault
      </StyledNavLink>
      <span style={{ height: '2rem', alignSelf: 'center' }}>View your claimed bonds in the vault for rewards !</span>
    </Wrapper>
  )
}
