import { ChainId } from 'moonbeamswap'
import React, { ButtonHTMLAttributes, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

import Logo from '../../assets/images/mainlogo.png'
import SmallLogo from '../../assets/images/logo.png'
import { useActiveWeb3React } from '../../hooks'
//import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
import Menu from '../Menu'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
// import VersionSwitch from './VersionSwitch'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean
}

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  text-decoration-style: unset;

  :hover {
    cursor: pointer;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const SmallUniIcon = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    transition: transform 0.3s ease;
    :hover {
      transform: rotate(-5deg);
    }
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
    display: inline;
`};

  display: none;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
`};
`

const HeaderElementNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; /* Change to fixed position */
  top: 0;
  left: 40%;
  background-color: #feee02;
  border-radius: 0.5rem;
  margin-top: 1rem;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.3);

  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `};
`

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #feee02;
  color: black;
  border: none;
  border-radius: 0.5rem;
  padding: 0.6rem 1.6em;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? 'white' : '#FEEE02')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.bg2};
    transition: background-color 0.3s ease;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: 0.2rem 0.5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin: 0.2rem 0.25rem;
  font-size: 1rem;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const URLText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.35rem;
  margin-right: 0rem;
  padding: 0.5rem;
  background-color: #feee02;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  align-items: flex-end;
`};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.STANDALONE]: 'Moonbase Standalone',
  [ChainId.MOONROCK]: 'Moonrock Rococo',
  [ChainId.TENET]: 'Tenet'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  //const [isDark] = useDarkModeManager()

  const [url, setUrl] = useState('swap')

  // Update the URL state whenever the URL changes
  useEffect(() => {
    const handleLocationChange = () => {
      const url = window.location.href.substring('http://localhost:3000/#/'.length)
      const newUrl = url.split('/')[0]
      setUrl(newUrl)
    }

    // Listen for URL changes
    window.addEventListener('popstate', handleLocationChange)

    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('popstate', handleLocationChange)
    }
  }, [])

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'flex-start' }} padding="1rem 1rem 0 1rem">
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              <img style={{ height: 50 }} src={Logo} alt="logo" />
            </UniIcon>
            <SmallUniIcon>
              <img style={{ height: 50 }} src={SmallLogo} alt="logo" />
            </SmallUniIcon>
          </Title>
        </HeaderElement>
        <HeaderElementNav>
          <Button
            onClick={() => {
              window.location.href = `${window.location.origin}/#/swap`
            }}
          >
            Trade
          </Button>
          <Button
            onClick={() => {
              window.location.href = `${window.location.origin}/#/bond`
            }}
          >
            Bond
          </Button>
          <Button
            onClick={() => {
              window.location.href = `${window.location.origin}/#/earn`
            }}
          >
            Earn
          </Button>
          {/* <Button onClick={() => { window.location.href = `${window.location.origin}/#/farm`; }} >
          Farming 
        </Button> */}
          <Button
            onClick={() => {
              window.open("https://snapshot.org/#/tendiedao.eth", "_blank");
            }}
          >
            Vote
          </Button>
        </HeaderElementNav>
        <HeaderControls>
          <HeaderElement>
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} TEN
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/* <VersionSwitch /> */}
            <Settings />
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
