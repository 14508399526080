import React from 'react';
import AppBody from '../AppBody';
import TypedOutText from '../../components/TypedOutText'; // Import the TypedOutText component from its file

export default function Launchpad() {
  const textToType = 'Hello, this section is still being worked on, come back later!';

  return (
    <AppBody>
      <TypedOutText text={textToType} />
    </AppBody>
  );
}
