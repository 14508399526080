import React from 'react'
import styled from 'styled-components'
import { AdvancedBondingDetails } from './AdvancedBondingDetails'

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  padding-top: calc(16px + 2rem);
  padding-bottom: 20px;
  margin-top: -2rem;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.text2};
  background-color: ${({ theme }) => theme.advancedBG};
  z-index: -1;

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`

interface Bond {
  AmountInDollars: number;
  VestingTerm: number;
  MaxBondAmount: number;
}

interface AdvancedBondingDetailsDropdownProps {
  bond: Bond | null;
}

export default function AdvancedBondingDetailsDropdown({ bond }: AdvancedBondingDetailsDropdownProps) {
  const showFooter = Boolean(bond);

  return (
    <AdvancedDetailsFooter show={showFooter}>
      {bond && <AdvancedBondingDetails bond={bond} />}
    </AdvancedDetailsFooter>
  )
}
