/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import { TENET } from 'moonbeamswap'
import { useBondNFTContract, useLPTokenContract, useTendieTokenContract } from '../../hooks/useContract'
import { useActiveWeb3React } from '../../hooks'
import { BigNumber } from 'ethers'
import CurrencyLogo from '../../components/CurrencyLogo'

const LabelHero = styled.p`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text5};
  font-size: 4rem;
  font-weight: 700;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  margin: 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const LabelText = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text5};
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  padding: 0.75rem 1rem 0 1rem;
  margin: 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 1.5rem;
`};
`

export const CardWrapper = styled.div`
  position: relative;
  max-width: 220px;
  min-height: 100px;
  max-height: 150px;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  text-align: center;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  padding: 1rem;
  margin: 1rem;
`

export const TextWrapper = styled.div`
  position: relative;
  max-width: 200px;
  /* min-height: 10px; */
  /* width: 100%; */
  /* height: 100%; */
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  background: ${({ theme }) => theme.bg5};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  padding: 1rem;
  margin: 1rem;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  font-weight: 500;
  justify-content: space-between;
  justify-items: center;
  align-items: space-between;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 75%;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 60%;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
`};
`

export default function Home() {
  const theme = useContext(ThemeContext)

  const { account, library } = useActiveWeb3React()
  const LPContract = useLPTokenContract()
  const TendiTokenContract = useTendieTokenContract()
  const bondNFTContract = useBondNFTContract()

  // const [stakingStats, setStakingStats] = useState<StakingStats | null>(null)
  const [tendiePrice, setTendiePrice] = useState(0)
  const [marketCap, setMarketCap] = useState(0)
  const [dayVol, setDayVol] = useState(0)
  const [NFTSold, setNFTSold] = useState(0)
  const [tvl, setTvl] = useState(0)
  const [treasury, setTreasury] = useState(0)

  useEffect(() => {
    const fetchStakingData = async () => {
      if (!account || !library || !LPContract || !TendiTokenContract || !bondNFTContract) return

      const reserves = await LPContract.getReserves()

      // Calc Bond NFT sold
      const NFTTotalSupply: BigNumber = await bondNFTContract.totalSupply()
      setNFTSold(+NFTTotalSupply)

      // Clac Treasury - top 2 Tendies-Tenet LP in usd
      const totalLp = await LPContract.totalSupply()
      const LpUsd = ((reserves[1] * 2) / totalLp) * 0.14
      let _treasury = 0

      const topAddr = ['0x9584329601571a4Dd2BdC5d47DE39524445C95d7', '0x299eBaD07039D255268957E4cDa5ac20E1169Adf']
      for (let i = 0; i < topAddr.length; i++) {
        const balance = await LPContract.balanceOf(topAddr[i])
        _treasury += (balance * LpUsd) / 10 ** 18
      }
      setTreasury(_treasury)
    }
    fetchStakingData()
  }, [account, library, TendiTokenContract, LPContract, bondNFTContract])

  useEffect(() => {
    const requestOptions: any = {
      method: 'GET',
      redirect: 'follow'
    }
    // get Tendi Token price
    fetch(
      'https://api.geckoterminal.com/api/v2/networks/tenet/tokens/0x0260f440aea04a1690ab183dd63c5596d66a9a43',
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const JSONresult = JSON.parse(result)
        const { price_usd, total_supply, decimals, volume_usd } = JSONresult.data.attributes
        setTendiePrice(price_usd)
        setMarketCap((price_usd * total_supply) / 10 ** decimals)
        setDayVol(volume_usd.h24)
      })
      .catch(error => console.log('error', error))

    fetch(
      'https://api.geckoterminal.com/api/v2/networks/tenet/tokens/0x0260f440aea04a1690ab183dd63c5596d66a9a43/pools',
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const JSONresult = JSON.parse(result)
        console.log('JSONresult', JSONresult.data)
        let _tvl = 0
        for (let i = 0; i < JSONresult.data.length; i++) {
          const { reserve_in_usd } = JSONresult.data[i].attributes
          _tvl += +reserve_in_usd
        }
        setTvl(_tvl)
        // const { price_usd, total_supply, decimals, volume_usd } = JSONresult.data.attributes
      })
      .catch(error => console.log('error', error))
  })

  return (
    <>
      <LabelHero>Tendieswap</LabelHero>
      <LabelText>The #1 Defi Hub & Dex on TENET with Protocol Owned Liquidity</LabelText>
      <Grid>
        <CardWrapper>
          Market Cap <TextWrapper>${(marketCap / 1000).toFixed()}k</TextWrapper>
        </CardWrapper>
        <CardWrapper>
          24h Vol <TextWrapper>${(+dayVol.toLocaleString()).toFixed(2)}</TextWrapper>
        </CardWrapper>
        <CardWrapper>
          TVL <TextWrapper>${(tvl / 1000).toFixed()}k</TextWrapper>
        </CardWrapper>
        <CardWrapper>
          Tendies Price <TextWrapper>${(+tendiePrice).toFixed(3)}</TextWrapper>
        </CardWrapper>
        <CardWrapper>
          Bonds Sold <TextWrapper>{NFTSold}</TextWrapper>
        </CardWrapper>
        <CardWrapper>
          Treasury <TextWrapper>$ {(treasury / 1000).toFixed()}k</TextWrapper>
        </CardWrapper>
      </Grid>
      <LabelText>Supported by the Tenet Foundation</LabelText>
      <a href="https://twitter.com/tenet_org/status/1676563082108182528" target="_blank">
        <CurrencyLogo currency={TENET} size={'4rem'} />
      </a>
      
    </>
  )
}
