import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  border-radius: 3rem;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ffffff;
`

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 12px;
  padding: 0.75rem 1rem 0rem 1rem;
  width: 100%;
  text-align: left;
`

const Stat = styled.text`
  margin-top: 12px;
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
`

const StatInsideWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const StatSign = styled.span`
  color: black;
  font-size: 14px;
  text-align: right;
  font-weight: 500;
  margin-left: 4px;
`

const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface StakingStats {
  TendiePrice: number
  TendieStaked: number
}

interface StakingStatsProps {
  stakingStats: StakingStats | null
}

export default function StakingStats({ stakingStats }: StakingStatsProps) {
  if (!stakingStats) {
    return (
      <StatsContainer>
        <Stat>Stats Loading...</Stat>
      </StatsContainer>
    )
  }

  const { TendiePrice, TendieStaked } = stakingStats

  /*
  MATH

  Want to show LP US price

  Want to show Bond ROI

  */

  // Calculate the progress based on the formula: progress = tokensBonded / 35000000

  //const DivConst = 1000000;

  return (
    <Wrapper>
      <StatsContainer>
        <StatWrapper>
          <span>Monthly Return</span>
          <StatInsideWrapper>
            <Stat>{(18750000 / TendieStaked).toFixed(2)}</Stat>
            <StatSign>%</StatSign>
          </StatInsideWrapper>
        </StatWrapper>
        <StatWrapper>
          <span>Value Locked</span>
          <StatInsideWrapper>
            <Stat>{(TendiePrice * TendieStaked).toLocaleString()}</Stat>
            <StatSign>$</StatSign>
          </StatInsideWrapper>
        </StatWrapper>
      </StatsContainer>
    </Wrapper>
  )
}
