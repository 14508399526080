import React, { useState, useRef, useEffect, useMemo } from 'react'
import { ethers } from 'ethers'
import { TransactionResponse } from '@ethersproject/providers'
import { useActiveWeb3React } from '../../hooks'
import { Token, ChainId, TokenAmount } from 'moonbeamswap'
import { useTokenBalances } from '../../state/wallet/hooks'
import styled from 'styled-components'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { bondingContract as bondingContractAddr } from '../../TENET_address.json'
import { ButtonConfirmed } from '../../components/Button'
import { useBondingContract } from '../../hooks/useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TendiesTokenContract, LPTokenContract } from '../../TENET_address.json'
//import Loader from '../../components/Loader'

const Wrapper = styled.div`
  display: flex;
  border-radius: 3rem;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ffffff;
`

const DepositContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 12px;
  padding: 0.75rem 1rem 0rem 1rem;
  width: 100%;
  text-align: left;
`

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
`

const Label = styled.text`
  color: black;
  font-weight: 500;
  font-size: 14px;
`

const Stat = styled.text`
  text-align: center;
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`

const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`

const StyledInput2Container = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  max-width: 50%;
  gap: 8px;
`

const StyledInput = styled.input`
  text-align: left;
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
  outline: none;
  padding: 3px !important;
  width: 100%;
  height: 28px;
  margin-bottom: 5px;
`

const DollarValue = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
`

interface DynamicDollarValueProps {
  position: number
}

const DynamicDollarValue = styled(DollarValue)<DynamicDollarValueProps>`
  text-align: left;
`

const StyledBalanceMax = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  `};
`

interface Bond {
  AmountInDollars: number
  VestingTerm: number
  MaxBondAmount: number
  LPTokens: number
  MaxPrice: number
  TokenPayout: number
}

interface BondDepositProps {
  bond: Bond | null
  setBond: React.Dispatch<React.SetStateAction<Bond | null>>
}

interface BondStats {
  BondPrice: number
  TendiePrice: number
  LPPrice: number
  TotalEmissions: number
  MaxLpBond: number
  TendieSupply: number
}

interface BondStatsProps {
  bondStats: BondStats | null
  setBondStats: React.Dispatch<React.SetStateAction<BondStats | null>>
}
function removeLeadingZeros(str: string) {
  return str.replace(/^0+/, match => (match.length > 1 ? match : ''))
}

function removeTrailingZeros(str: string) {
  return str.replace(/0+$/, '')
}

function truncateString(str: string, maxLength: number) {
  return str.slice(0, maxLength)
}

function limitSignificantFigures(number: number, maxSigFigs: number) {
  const numberString = number.toString()
  const dotIndex = numberString.indexOf('.')

  if (dotIndex !== -1) {
    let integerPart = numberString.substr(0, dotIndex)
    let decimalPart = numberString.substr(dotIndex + 1)

    integerPart = removeLeadingZeros(integerPart)
    decimalPart = removeTrailingZeros(decimalPart)

    if (integerPart.length + decimalPart.length <= maxSigFigs) {
      // No truncation needed
      return numberString
    }

    if (integerPart === '' || integerPart === '0') {
      const truncatedDecimalPart = truncateString(decimalPart, maxSigFigs - 1)
      const truncatedNumberString = '0.' + truncatedDecimalPart
      return truncatedNumberString
    }

    const truncatedDecimalPart = truncateString(decimalPart, maxSigFigs - integerPart.length)
    const truncatedNumberString = integerPart + (truncatedDecimalPart ? '.' + truncatedDecimalPart : '')
    return truncatedNumberString
  }

  return removeLeadingZeros(numberString)
}

/*
  function convertToTokenAmount(value: number, token: Token) {
    // Convert the numerical value to a string and parse it into a BigInt
    const amount = BigInt(value.toString());
  
    // Create a new TokenAmount object with the token and amount
    const tokenAmount = new TokenAmount(token, amount);
  
    return tokenAmount;
  }
  */

// ...

// ...

export default function BondingDeposit({ bond, setBond, bondStats, setBondStats }: BondDepositProps & BondStatsProps) {
  const { account, library } = useActiveWeb3React()

  const bondingContract = useBondingContract(bondingContractAddr)
  const addTransaction = useTransactionAdder()

  const lpToken = useMemo(() => new Token(ChainId.TENET, LPTokenContract, 18, 'TENLP-V2', 'Tendieswap LP-V2'), [])
  const tendieToken = useMemo(() => new Token(ChainId.TENET, TendiesTokenContract, 18, 'TENDIE', 'Tendie Token'), [])

  const balances = useTokenBalances(account ?? undefined, [lpToken, tendieToken])

  const [inputValue, setInputValue] = useState('0.0')
  const [dollarValuePosition, setDollarValuePosition] = useState<number>(0)
  const [approveAmount, setApproveAmount] = useState<TokenAmount>(new TokenAmount(lpToken, BigInt(1)))

  const LPBalance = parseFloat(balances?.[lpToken.address]?.toSignificant(10) || '0')
  const TendieBalance = parseFloat(balances?.[tendieToken.address]?.toSignificant(10) || '0')
  const MaxBond = 1000

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // Calculate the dynamic position of DollarValue based on the input value
    const inputElement = inputRef.current
    if (inputElement) {
      const inputValueLength = inputValue.length
      const position = +(14 * inputValueLength) // Adjust the multiplier based on the desired spacing
      setDollarValuePosition(position)
    }
  }, [inputValue])

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallback(approveAmount, bondingContractAddr)

  // check if user has gone through approval process, used to show two step buttons, reset on token change

  // mark when a user has submitted an approval, reset onTokenSelection for input field

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    // Check if inputValue contains only numbers (including decimals)
    if (/^\d*\.?\d*$/.test(inputValue)) {
      if (parseFloat(inputValue) > LPBalance) {
        if (inputValue != null) {
          setInputValue(limitSignificantFigures(LPBalance, 4).toString())
        }
        if (LPBalance < 1) {
          console.log(LPBalance)
        }
        setApproveAmount(new TokenAmount(lpToken, BigInt(LPBalance * 10 ** 18)))
        if (inputValue === '0' || inputValue === '' || parseFloat(inputValue) === 0) {
          setBond(null)
        } else {
          const amountInDollars = bondStats?.LPPrice ? (LPBalance ? LPBalance * bondStats.LPPrice : 0) : 0

          setBond({
            AmountInDollars: amountInDollars,
            VestingTerm: 5,
            MaxBondAmount: MaxBond,
            LPTokens: LPBalance,
            MaxPrice: 1,
            TokenPayout: 1
          })
        }
      } else {
        if (inputValue != null) {
          setInputValue(inputValue)
        }
        if (parseFloat(inputValue) > 0) {
          setApproveAmount(new TokenAmount(lpToken, BigInt(parseFloat(inputValue) * 10 ** 18)))
        }
        if (inputValue === '0' || inputValue === '' || parseFloat(inputValue) === 0) {
          setBond(null)
        } else {
          const amountInDollars = bondStats?.LPPrice ? parseFloat(inputValue) * bondStats.LPPrice : 0

          setBond({
            AmountInDollars: amountInDollars,
            VestingTerm: 5,
            MaxBondAmount: MaxBond,
            LPTokens: LPBalance,
            MaxPrice: 1,
            TokenPayout: 1
          })
        }
      }
    }
  }

  const onMaxBond = () => {
    setInputValue(limitSignificantFigures(LPBalance, 4).toString())
    setApproveAmount(new TokenAmount(lpToken, BigInt(LPBalance * 10 ** 18)))
    const amountInDollars = bondStats?.LPPrice ? (LPBalance ? LPBalance * bondStats.LPPrice : 0) : 0

    setBond({
      AmountInDollars: amountInDollars,
      VestingTerm: 5,
      MaxBondAmount: MaxBond,
      LPTokens: LPBalance,
      MaxPrice: 1,
      TokenPayout: 1
    })
  }

  async function handleDeposit() {
    if (bondingContract && bond && library) {
      try {
        const bondPrice = await bondingContract.bondPrice()
        const maxPayout = await bondingContract.maxPayout()

        const payout = await bondingContract.payoutFor(ethers.utils.parseEther(inputValue))

        if (payout / 10 ** 18 > maxPayout / 10 ** 18) {
          console.log('Error Max Bond Size')
          alert('Amount over Maximum Bond Size')
        } else {
          const depositValue = ethers.utils.parseEther(inputValue)
          const maxPrice = 0.05 * bondPrice + bondPrice // Pass the number as a string to BigNumber.from

          try {
            bondingContract
              .deposit(depositValue, maxPrice)
              .then((response: TransactionResponse) => {
                addTransaction(response, {
                  summary: 'Bond ' + inputValue + 'Tendies Succeed.'
                })
              })
              .catch((error: Error) => {
                console.debug('Failed to bond token', error)
              })
          } catch (error) {
            console.error('Error depositing:', error)
          }

          // const tx = await bondingContract.deposit(depositValue, maxPrice)
          // await tx.wait()
          // console.log('Deposit successful')
          // alert('Deposit Succesful!')
        }
      } catch (error) {
        console.error('Error depositing:', error)
      }
    }
  }

  const tokenEmissions =
    inputValue === '0' || inputValue === '' || parseFloat(inputValue) === 0 || !bondStats
      ? 0
      : parseFloat(inputValue) / bondStats.BondPrice
  const LPUsd =
    inputValue === '0' || inputValue === '' || parseFloat(inputValue) === 0
      ? 0
      : bondStats?.LPPrice
      ? parseFloat(inputValue) * bondStats.LPPrice
      : 0
  const TokenUsd = bondStats?.TendiePrice ? bondStats.TendiePrice * tokenEmissions : 0

  return (
    <Wrapper>
      <DepositContainer>
        <InputContainer>
          <Label>From</Label>
          <Label>Balance: {LPBalance.toFixed(2)}</Label>
        </InputContainer>

        <InputContainer>
          <StyledInputContainer>
            <div>
              <StyledInput maxLength={7} ref={inputRef} type="text" value={inputValue} onChange={handleInputChange} />
              <DynamicDollarValue position={dollarValuePosition}>${LPUsd.toFixed(2)}</DynamicDollarValue>
            </div>
            <StyledBalanceMax onClick={onMaxBond}>MAX</StyledBalanceMax>
          </StyledInputContainer>

          <Stat>LP TOKEN</Stat>
        </InputContainer>
        <InputContainer>
          <Label>To</Label>
          <Label>Balance: {TendieBalance.toFixed(2)}</Label>
        </InputContainer>
        <InputContainer>
          <StyledInput2Container>
            <Stat>{tokenEmissions === 0 ? tokenEmissions.toFixed(2) : limitSignificantFigures(tokenEmissions, 6)}</Stat>
            <DollarValue>${TokenUsd.toFixed(2)}</DollarValue>
          </StyledInput2Container>

          <Stat>TENDIES</Stat>
        </InputContainer>
      </DepositContainer>

      <ButtonContainer>
        <ButtonConfirmed disabled={approval !== ApprovalState.NOT_APPROVED} onClick={approveCallback}>
          Approve
        </ButtonConfirmed>
      </ButtonContainer>
      <ButtonContainer>
        {account && (
          <ButtonConfirmed disabled={!ApprovalState.APPROVED || bond === null} onClick={handleDeposit}>
            Bond
          </ButtonConfirmed>
        )}
      </ButtonContainer>
    </Wrapper>
  )
}
