import React from 'react'
import AppBody from '../AppBody'
import BondVaultList from '../../components/BondVaultList' // Import the BondingStats component
import StakeVaultTabs from '../../components/StakeVaultTabs'

export default function BondVault() {
  return (
    <AppBody>
      {/* Tab */}
      <StakeVaultTabs active={'vault'} />
      {/* Functionality */}
      <BondVaultList />
    </AppBody>
  )
}
